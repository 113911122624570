import axios from 'axios';

let service = axios.create({
  baseURL: 'https://www.doit365.net/', //域名信息
  timeout: 10000
});

// 请求拦截 可在请求头中加入token等
service.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// 响应拦截 对响应消息作初步的处理
service.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response) {
      switch (error.response.states) {
        case 400: {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            console.log('400错误: ', error.response.data.message);
          }
          break;
        }
      }
    }
  }
);

export default service;
